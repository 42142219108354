// import { Step, StepLabel, Stepper, useMediaQuery } from "@mui/material";
// import { Box } from "@mui/system";
// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { displayError } from "../Functions/RegistrationFunctions";

export default function GuestStatus() {}
//   // const navigate = useNavigate();
//   const { confirmationNumber, unitNumber } = useParams();
//   const [isApproved, setApproval] = useState(false);
//   const passStatus = useState("Pass Approved/Denied");
//   const [activeStep, setActiveStep] = React.useState(2);
//   const [guestPassStatus, setGuestPassStatus] = useState("Pass Status");
//   const isMobile = useMediaQuery("(max-width:600px)");
//   const [guestPassData, setGuestPassData] = useState({
//     guestName: "",
//     passType: "",
//     unitNumber: "",
//     phoneNumber: "",
//     vehicle: {
//       vehicleMake: "",
//       vehicleModel: "",
//       vehicleColor: "",
//       vehicleState: "",
//       vehicleTagNumber: "",
//     },
//     spotNumber: "",
//     status: "",
//     confirmationNumber: "",
//   });

//   const steps = [
//     "Pass Request Submitted",
//     "Awaiting Resident Approval",
//     guestPassStatus.includes("awaiting resident approval")
//       ? passStatus
//       : guestPassStatus,
//   ];

//   useEffect(() => {
//     async function pullParkingData() {
//       const response = await axios.get(
//         `http://localhost:8080/api/guest/fetch/confirmationnumber`,
//         {
//           params: {
//             confirmationNumber,
//             unitNumber,
//           },
//         }
//       );

//       setGuestPassData(response.data.body);

//       try {
//         if (
//           guestPassData.status.includes("approved") ||
//           guestPassData.status.includes("denied")
//         ) {
//           setGuestPassStatus(guestPassData.status);
//           goToNextStep();
//           if (guestPassStatus.includes("approved")) {
//             setApproval(true);
//           }
//         }
//       } catch (error) {
//         console.log(error);
//         displayError(
//           "Pass Not Found",
//           "Sorry, we are currently unable to locate your pass in our records. Please refresh the page and try again or try registering your vehicle again."
//         );
//       }
//     }

//     pullParkingData();
//   }, [guestPassData]);

//   function goToNextStep() {
//     if (activeStep < 3) {
//       setActiveStep((activeStep) => activeStep + 1);
//     }
//   }

//   const isStepFailed = (step) => {
//     return step === 2;
//   };

//   return (
//     <Box
//       sx={{
//         display: "flex",
//         borderRadius: "5px",
//         alignItems: "center",
//         fontFamily: "Poppins",
//         flexDirection: "column",
//         marginTop: isMobile ? 0 : "5rem",
//         marginX: isMobile ? "1rem" : "auto",
//         paddingX: isMobile ? "1rem" : "25rem",
//         paddingBottom: "5rem",
//       }}
//     >
//       <Box padding={isMobile ? 2 : 4} width="100%">
//         <Stepper activeStep={activeStep}>
//           {steps.map((label, index) => {
//             const labelProps = {};
//             if (isStepFailed(index) && guestPassStatus.includes("denied")) {
//               labelProps.error = true;
//             } else {
//               labelProps.error = false;
//             }

//             return (
//               <Step key={label}>
//                 <StepLabel style={{ fontSize: "1rem" }} {...labelProps}>
//                   {label}
//                 </StepLabel>
//               </Step>
//             );
//           })}
//         </Stepper>
//       </Box>
//     </Box>
//   );
// }
