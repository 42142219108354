import {
  Box,
  Stack,
  Radio,
  Button,
  TextField,
  Typography,
  createTheme,
  ThemeProvider,
  useMediaQuery,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import DesktopNavBar from "../Components/DesktopNavBar";
import StatesDropDown from "../Components/StatesDropDown";
import UnitNumberDropDown from "../Components/UnitsDropDown";

export default function PassFinder() {
  const [guestName, setGuestName] = useState("");
  const [unitNumber, setUnitNumber] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [guestPhoneNumber, setPhoneNumber] = useState("");
  // const [vehicleState, setVehicleState] = useState("");
  const [vehicleTagNumber, setVehicleTagNumber] = useState("");
  const [isLicensePlate, setLicensePlateLookup] = useState(false);
  const [confirmationNumber, setConfirmationNumber] = useState("");

  const theme = createTheme({
    typography: {
      fontSize: 18,
    },
    palette: {
      ochre: {
        main: "#a881af",
        light: "#E9DB5D",
        dark: "#80669d",
        contrastText: "#242105",
      },
    },
  });

  async function submitRequest() {
    console.log(unitNumber);
    if (isLicensePlate) {
      // await axios
      //   .pi("http://localhost:8080/api/guest/fetch/tagNumber", {
      //     params: {
      //       vehicleTagNumber,
      //       vehicleState,
      //     },
      //   })
      //   .then((result) => {
      //     console.log(result);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    } else {
      // await axios
      //   .get("http://localhost:8080/api/guest/fetch/confirmationnumber", {
      //     params: {
      //       confirmationNumber,
      //       unitNumber,
      //     },
      //   })
      //   .then((result) => {
      //     console.log(result);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    }
  }

  /* Pulls the selection value provided by the state tag dropdown menu and assigns it to the
  state variable in the guestPassData Object. This function is passed as a prop into the StatesDropDown.js file*/
  // function pull_stateTag_data(state) {
  //   setVehicleState(state);
  //   setLicensePlateLookup(true);
  // }

  /* Pulls the selection value provided by the unit number dropdown menu and assigns it to the
  unitNumber variable in the guestPassData Object. This function is passed as a prop into the UnitNumbersDropDown.js file*/
  function pull_unitNumber_data(unitNumber) {
    setUnitNumber(unitNumber);
  }

  /**  Handles phone number entry within the phone number field.
  Assigns the value to the phoneNumber variable in the guestPassData Object.  */
  function handlePhoneNumberInput(number) {
    setPhoneNumber(number);
  }

  function handleRadioSelection(event) {
    const selection = event.target.value;

    if (selection === "tagNumber") {
      setLicensePlateLookup(true);
    } else {
      setLicensePlateLookup(false);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <DesktopNavBar />
      <Box
        sx={{
          display: "flex",
          borderRadius: "5px",
          alignItems: "center",
          fontFamily: "Poppins",
          paddingBottom: "5rem",
          flexDirection: "column",
          marginTop: isMobile ? 0 : "5rem",
          marginX: isMobile ? "1rem" : "auto",
          paddingX: isMobile ? "1rem" : "25rem",
        }}
      >
        <Box padding={isMobile ? 2 : 4} width="100%">
          <Stack spacing={3}>
            <Box>
              <Typography textAlign={"center"} fontSize={isMobile ? 23 : 30}>
                Guest Parking Pass Finder
              </Typography>

              <Typography fontSize={isMobile ? 18 : 15}>
                Please enter your information below to locate your previous
                parking pass(es).
              </Typography>
            </Box>

            {["Guest Name", "Guest Phone Number"].map((label, index) => (
              <Box key={index}>
                <Typography fontSize={18}>{label}*</Typography>
                {index !== 1 ? (
                  <TextField
                    fullWidth
                    name={label}
                    value={guestName}
                    onChange={setGuestName}
                    placeholder={"Enter your full name"}
                  />
                ) : (
                  <PhoneInput
                    country={"us"}
                    disableDropdown
                    disableCountryCode
                    disableCountryGuess
                    value={guestPhoneNumber}
                    onChange={handlePhoneNumberInput}
                    inputStyle={{ width: "100%", backgroundColor: "#ede8f5" }}
                    specialLabel=""
                    inputProps={{
                      required: true,
                      autoFocus: true,
                    }}
                  />
                )}
              </Box>
            ))}

            <Box>
              <Typography fontSize={18}>Unit Number*</Typography>
              <UnitNumberDropDown onChange={pull_unitNumber_data} />
            </Box>

            <Box>
              <Typography fontSize={isMobile ? 20 : 25} fontWeight={"bold"}>
                Look Up Method
              </Typography>
              <Typography fontSize={15}>
                Enter the tag number to retrieve multiple parking passes or use
                the confirmation number to access a specific pass
              </Typography>
            </Box>

            <FormControl>
              <RadioGroup
                row
                defaultValue={"tagNumber"}
                onChange={handleRadioSelection}
              >
                <FormControlLabel
                  value="tagNumber"
                  label="Tag Number"
                  control={<Radio />}
                />
                <FormControlLabel
                  value="Confirmation Number"
                  label="Confirmation Number"
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>

            {isLicensePlate ? (
              <React.Fragment>
                <Box>
                  <Typography fontSize={18}>License Plate Number</Typography>
                  <TextField
                    fullWidth
                    value={vehicleTagNumber}
                    name={"vehicleTagNumber"}
                    placeholder={"Enter license plate number"}
                    onChange={(e) => setVehicleTagNumber(e.target.value)}
                  />
                </Box>

                <Box>
                  <Typography fontSize={18}>Vehicle State</Typography>
                  <StatesDropDown />
                </Box>
              </React.Fragment>
            ) : (
              <Box>
                <Typography fontSize={18}>Pass Confirmation Number</Typography>
                <TextField
                  fullWidth
                  value={confirmationNumber}
                  name={"confirmationNumber"}
                  placeholder={"Enter confirmation number"}
                  onChange={(e) => setConfirmationNumber(e.target.value)}
                />
              </Box>
            )}

            <Stack textAlign={"center"} spacing={5}>
              <Button
                fullWidth
                color="ochre"
                variant="contained"
                onClick={submitRequest}
              >
                <Typography fontWeight={"bold"} style={{ color: "white" }}>
                  Search
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
