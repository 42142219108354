import React from "react";
import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";

export default function SubmissionInformation() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const guestFields = ["guestName", "phoneNumber", "passType", "unitNumber"];
  const vehicleFields = ["make", "model", "color", "tagNumber", "state"];

  return (
    <Box
      sx={{
        width: "100%",
        marginX: "auto",
        display: "flex",
        fontFamily: "Poppins",
        paddingBottom: 5,
        flexDirection: "column",
        marginTop: isMobile ? 4 : "2rem",
        maxWidth: isMobile ? "650px" : "800px",
      }}
    >
      <Stack gap={3} paddingTop={3} width={"100%"}>
        <Typography fontWeight={"bold"} textAlign={"center"} fontSize={30}>
          Review & Submit
        </Typography>

        <Typography
          lineHeight={2}
          fontSize={17}
          component={"span"}
          sx={{ padding: isMobile ? "1rem" : "0rem" }}
        >
          Please review the information below for accuracy. If all details are
          correct, click the "Submit" button at the bottom of the page to send
          your guest pass request to the resident for approval. If you need to
          make any changes, use the "Back" button to return to the previous
          section and correct any errors. Ensuring that each field is fully
          completed is essential for accurate processing of the parking pass and
          to avoid any risk of vehicle towing.
        </Typography>

        <Typography textAlign={"center"} fontSize={isMobile ? 25 : 30}>
          Guest Information
        </Typography>

        {["Guest Name", "Guest Phone Number", "Pass Type", "Unit Number"].map(
          (label, index) => (
            <Stack
              key={index}
              sx={{ paddingLeft: isMobile ? "0.5rem" : "3rem" }}
            >
              <Typography
                fontSize={isMobile ? 20 : 18}
                lineHeight={isMobile ? 1.5 : 2}
              >
                <strong>{label}</strong>:{" "}
                {window.sessionStorage.getItem(guestFields[index])}
              </Typography>
            </Stack>
          )
        )}

        <Typography textAlign={"center"} fontSize={isMobile ? 25 : 30}>
          Vehicle Information
        </Typography>

        {[
          "Vehicle Make",
          "Vehicle Model",
          "Vehicle Color",
          "Vehicle Tag Number",
          "Vehicle State",
        ].map((label, index) => (
          <Stack key={index} sx={{ paddingLeft: isMobile ? "0.5rem" : "3rem" }}>
            <Typography
              fontSize={isMobile ? 20 : 18}
              lineHeight={isMobile ? 1.5 : 2}
            >
              <strong>{label}</strong>:{" "}
              {window.sessionStorage.getItem(vehicleFields[index])}
            </Typography>
          </Stack>
        ))}

        <Button
          sx={{
            maxWidth: "50vh",
            marginTop: isMobile ? 2 : 0,
            marginLeft: isMobile ? 0 : 11,
          }}
          color={"ochre"}
          variant={"contained"}
        >
          <Typography
            color={"white"}
            fontWeight={"bold"}
            fontFamily={"poppins"}
          >
            Submit
          </Typography>
        </Button>
      </Stack>
    </Box>
  );
}
