import React, { useState } from "react";
import usStates from "./JSON/usaStates.json";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function StatesDropDown(props) {
  const [vehicleState, setVehicleState] = useState("");

  const handleChange = (e) => {
    setVehicleState(e.target.value);
    props.onChange(e.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>Vehicle State</InputLabel>
      <Select
        required
        value={vehicleState}
        label={"Vehicle State"}
        onChange={handleChange}
      >
        {usStates.map((state) => {
          return (
            <MenuItem key={state.abbreviation} value={state.name}>
              {state.name}
            </MenuItem>
          );
        })}{" "}
      </Select>
    </FormControl>
  );
}
