import About from "./Pages/About";
import LoginPage from "./Pages/Login";
import PassFinder from "./Pages/PassFinder";
import GuestStatus from "./Pages/GuestStatus";
import Registration from "./Pages/Registration";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ForgotPassword from "./Pages/ForgotPassword";
import { createTheme, ThemeProvider } from "@mui/material";
import Template from "./Pages/Template";
import React from "react";

function App() {
  const theme = createTheme({
    typography: {
      fontfamily: "Poppins ,sans-serif",
      fontstyle: "light",
      fontweight: 500,
    },

    palette: {
      ochre: {
        main: "#a881af",
        light: "#E9DB5D",
        dark: "#80669d",
        contrastText: "#242105",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<Template />}></Route>
          <Route path={"/forgotpassword"} element={<ForgotPassword />} />
          <Route path={"/about"} element={<About />} />
          {["/registration", "/userPortal"].map((path, index) => (
            <Route path={path} element={<Registration />} key={index} />
          ))}
          <Route path="/rules" element={<About />}></Route>
          <Route path="/search" element={<PassFinder />}></Route>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route
            path="/gueststatus/:confirmationNumber/:unitNumber?"
            element={<GuestStatus />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
