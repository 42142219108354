import {
  Box,
  Button,
  createTheme,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import auth from "../firebase";
import Swal from "sweetalert2";

import React, { useState } from "react";
// import MobileNavBar from "../Components/MobileNavBar";
// import DesktopNavBar from "../Components/DesktopNavBar";
import { isEmpty } from "../Functions/RegistrationFunctions";
import { signInWithEmailAndPassword } from "@firebase/auth";
import { Link, useNavigate } from "react-router-dom";

export default function LoginPage({ isMenuOpen }) {
  const navigate = useNavigate();
  const [hasNoEmail, setNoEmail] = useState(false);
  const [hasNoPassword, setNoPassword] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const [user, setUserAccount] = useState({
    email: "",
    password: "",
  });

  const theme = createTheme({
    typography: {
      fontSize: 15,
    },
    palette: {
      ochre: {
        main: "#a881af",
        light: "#E9DB5D",
        dark: "#80669d",
        contrastText: "#242105",
      },
      black: {
        main: "#000000",
      },
    },
  });

  function handleInput(e) {
    const { name, value } = e.target;
    setUserAccount({ ...user, [name]: value });
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      signInUser();
    }
  }

  function checkInputs() {
    if (isEmpty(user.email)) {
      setNoEmail(true);
    }

    if (isEmpty(user.password)) {
      setNoPassword(true);
    }

    if (!isEmpty(user.email)) {
      setNoEmail(false);
    }

    if (!isEmpty(user.password)) {
      setNoPassword(false);
    }

    return !hasNoEmail && !hasNoPassword;
  }

  function signInUser() {
    // Check if the input fields are valid
    if (checkInputs()) {
      // Attempt to sign in with email and password
      signInWithEmailAndPassword(auth, user.email, user.password)
        .then(() => {
          // Redirect the user upon successful sign-in
          routeChange();
        })
        .catch((error) => {
          // Handle sign-in errors
          Swal.fire({
            icon: "error",
            title: "Sign-in error",
            text: "Failed to sign in. Please check your email and/or password and try again.",
          });
        });
    }
  }

  function routeChange() {
    navigate("/userPortal/");
  }

  return (
    <ThemeProvider theme={theme}>
      {isMenuOpen ? (
        <React.Fragment>
          <Box
            sx={{
              width: "90%",
              marginX: "auto",
              display: "flex",
              paddingX: "1rem",
              maxWidth: "600px",
              alignItems: "center",
              fontFamily: "Poppins",
              paddingBottom: "5rem",
              flexDirection: "column",
              marginTop: isMobile ? 4 : "5rem",
            }}
          >
            <Stack
              gap={2.5}
              width={"100%"}
              padding={isMobile ? 2 : 4}
              sx={{ background: "#", borderRadius: 5 }}
            >
              <Box textAlign={"center"}>
                <Typography fontSize={30}>Sign In</Typography>
              </Box>
              {/* end of login box */}

              <Stack gap={4}>
                <Stack>
                  <Typography>Email Address</Typography>
                  <TextField
                    fullWidth
                    name={"email"}
                    error={hasNoEmail}
                    inputMode={"email"}
                    onChange={(e) => handleInput(e)}
                    onKeyDown={(e) => handleKeyPress(e)}
                  />
                  {hasNoEmail ? (
                    <Typography align={"right"} fontSize={15}>
                      Email address field cannot be left empty
                    </Typography>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </Stack>
                {/* end of username stack */}

                <Stack>
                  <Typography>Password</Typography>
                  <TextField
                    fullWidth
                    name={"password"}
                    type={"password"}
                    error={hasNoPassword}
                    onChange={(e) => handleInput(e)}
                    onKeyDown={(e) => handleKeyPress(e)}
                  />
                  {hasNoPassword ? (
                    <Typography align={"right"} fontSize={15}>
                      Password cannot be left empty
                    </Typography>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </Stack>
                {/* end of password stack */}
              </Stack>
              {/* end of username and password stacks */}

              <Box sx={{ justifyItems: "flex-start" }}>
                <Typography textAlign={"right"} fontSize={isMobile ? 18 : 20}>
                  <Link to={"/forgotpassword"}>Forgot Password?</Link>
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  color={"ochre"}
                  onClick={signInUser}
                  variant={"contained"}
                  sx={{ width: "70%" }}
                >
                  <Typography color={"white"} fontWeight={"bold"}>
                    Log in
                  </Typography>
                </Button>
              </Box>
            </Stack>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </ThemeProvider>
  );
}
