import {
  Box,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import StatesDropDown from "../StatesDropDown";

export default function VehicleInformation() {
  const isMobile = useMediaQuery("min-width:600px");
  const [vehicleInformation, setVehicleInformation] = useState({
    make: "",
    model: "",
    color: "",
    tagNumber: "",
    state: "",
  });

  const fields = ["make", "model", "color", "tagNumber", "state"];

  function handleInput(e) {
    const { name, value } = e.target;
    setVehicleInformation({
      ...vehicleInformation,
      [name]: value,
    });

    window.sessionStorage.setItem(name, value);
  }

  /* Pulls the selection value provided by the state tag dropdown menu and assigns it to the 
  state variable in the guestPassData Object. This function is passed as a prop into the StatesDropDown.js file*/
  function pull_stateTag_data(state) {
    vehicleInformation.state = state;
    window.sessionStorage.setItem("state", state);
  }

  return (
    <Box
      sx={{
        width: "100%",
        marginX: "auto",
        display: "flex",
        alignItems: "center",
        fontFamily: "Poppins",
        flexDirection: "column",
        marginTop: isMobile ? 4 : "2rem",
        maxWidth: isMobile ? "650px" : "800px",
      }}
    >
      <Stack gap={5} paddingTop={3} width={"100%"}>
        <Typography
          fontWeight={"bold"}
          textAlign={"center"}
          fontSize={isMobile ? 25 : 30}
        >
          Vehicle Information
        </Typography>

        <Box sx={{ paddingLeft: isMobile ? "2rem" : "0rem" }}>
          <Typography component={"span"} fontSize={17}>
            Next, we will need the vehicle's information to complete the
            registration. Please provide the following details about the guest's
            vehicle:
            <ol>
              <li>
                Make: The manufacturer of the vehicle (e.g., Toyota, Honda).
              </li>
              <li>
                Model: The specific model of the vehicle (e.g., Camry, Accord).
              </li>
              <li>Color: The color of the vehicle.</li>
              <li>Tag Number: The vehicle's license plate number.</li>
              <li>State: The state where the vehicle is registered.</li>
            </ol>
            It is essential that each field is fully completed to ensure
            accurate and efficient processing of the parking pass and to prevent
            any risk of vehicle towing. Please proceed by filling out the fields
            below.
          </Typography>
        </Box>

        {[
          "Vehicle Make",
          "Vehicle Model",
          "Vehicle Color",
          "Vehicle Tag Number",
        ].map((label, index) => (
          <Stack key={index}>
            <TextField
              fullWidth
              key={index}
              label={label}
              name={fields[index]}
              onChange={handleInput}
            />
          </Stack>
        ))}

        <Stack paddingBottom={5}>
          <StatesDropDown onChange={pull_stateTag_data} />
        </Stack>
      </Stack>
    </Box>
  );
}
