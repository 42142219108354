import React, { useState } from "react";
import {
  Box,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import UnitNumberDropDown from "../UnitsDropDown";
import ParkingPassType from "../PassTypes";

export default function GuestInformation() {
  const isMobile = useMediaQuery("min-width:600px");
  const [guestPassData, setGuestPassData] = useState({
    guestName: "",
    passType: "",
    unitNumber: "",
    phoneNumber: "",
  });

  /**
   *
   * @param {*} name
   */
  function handleGuestName(name) {
    setGuestPassData({ ...guestPassData, guestName: name });
    window.sessionStorage.setItem("guestName", name);
  }

  function handlePhoneNumberInput(number) {
    setGuestPassData((prevState) => ({
      ...prevState,
      phoneNumber: number,
    }));
    window.sessionStorage.setItem("phoneNumber", number);
  }

  /**
   *
   * @param {String} unitNumber
   *
   * Pulls the selection value provided by the unit number dropdown menu and assigns it to the
   * unitNumber variable in the guestPassData Object. This function is passed as a prop into the UnitNumbersDropDown.js file*/
  function pull_unitNumber_data(unitNumber) {
    guestPassData.unitNumber = unitNumber;
    window.sessionStorage.setItem("unitNumber", unitNumber);
  }

  /* Pulls the selection value provided by the pass type dropdown menu and assigns it to the 
  passType variable in the guestPassData Object. This function is passed as a prop into the PassType.js file*/
  function pull_passType_data(passType) {
    guestPassData.passType = passType;
    window.sessionStorage.setItem("passType", passType);
  }

  return (
    <Box
      sx={{
        width: "100%",
      
        display: "flex",
        paddingBottom: 5,
        alignItems: "center",
        fontFamily: "Poppins",
        flexDirection: "column",
        marginTop: isMobile ? 4 : "2rem",
        maxWidth: isMobile ? "650px" : "800px",
      }}
    >
      <Stack gap={5} paddingTop={3} width={"100%"}>
        <Typography
          fontWeight={"bold"}
          textAlign={"center"}
          fontSize={isMobile ? 25 : 30}
        >
          Guest Infromation
        </Typography>

        <Typography component={"span"} fontSize={17}>
          First, please provide the following details to complete your parking
          pass registration. Accurate information is essential to prevent any
          risk of vehicle towing:
          <ol>
            <li>Guest Name: Your full name.</li>
            <li>Phone Number: A valid contact number.</li>
            <li>
              Pass Type: Specify the type of parking pass (e.g., Temporary,
              Permanent).
            </li>
            <li>
              Unit Number: The unit or apartment number you are visiting or
              staying in.
            </li>
          </ol>
        </Typography>

        <TextField
          fullWidth
          name={"guestName"}
          label={"Guest Name"}
          variant={"outlined"}
          onChange={(e) => handleGuestName(e.target.value)}
        />

        <Box width="100%">
          <PhoneInput
            country={"us"}
            disableDropdown
            disableCountryCode
            disableCountryGuess
            value={guestPassData.phoneNumber}
            onChange={handlePhoneNumberInput}
            inputStyle={{
              width: "100%",
              backgroundColor: "hwb(60 81% 10%)",
            }}
            specialLabel=""
            placeholder="Guest Phone Number"
            inputProps={{
              required: true,
            }}
          />
        </Box>

        <UnitNumberDropDown onChange={pull_unitNumber_data} />

        <ParkingPassType onChange={pull_passType_data} />
      </Stack>
    </Box>
  );
}
