import React, { useState } from "react";
import unitNumbers from "./JSON/unitNumbers.json";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function UnitNumberDropDown(props) {
  const [unitNumber, setUnitNumber] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    setUnitNumber(value);
    props.onChange(value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>Unit Number</InputLabel>
      <Select
        required
        value={unitNumber}
        label={"Unit Number"}
        onChange={handleChange}
      >
        {unitNumbers.map((unit, i) => {
          return (
            <MenuItem key={i} value={unit.unit_number}>
              {unit.unit_number}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
