import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PhoneInput from "react-phone-input-2";
import Typography from "@mui/material/Typography";
import MobileNavBar from "../Components/MobileNavBar";
import ParkingPassType from "../Components/PassTypes";
import DesktopNavBar from "../Components/DesktopNavBar";
import UnitNumberDropDown from "../Components/UnitsDropDown";
import GuestInformation from "../Components/Forms/GuestInformation";
import ParkingSpotsDropDown from "../Components/ParkingSpotDropDown";
import VehicleInformation from "../Components/Forms/VehicleInformation";
import SubmissionInformation from "../Components/Forms/SubmissionConfirmation";
import { Stack, useMediaQuery, MobileStepper, TextField } from "@mui/material";
import StatesDropDown from "../Components/StatesDropDown";

export default function Template() {
  const [isActive, setIsActive] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");

  const textFieldNames = [
    "guestName",
    "phoneNumber",
    "vehicleMake",
    "vehicleModel",
    "vehicleColor",
    "vehicleTagNumber",
  ];

  const steps = [
    "Welcome",
    "Guest and Unit Information",
    "Vehicle Information",
    "Confirmation",
  ];

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function fetchStepForm(step) {
    switch (step) {
      case 0:
        return welcomePage();
      case 1:
        return <GuestInformation />;
      case 2:
        return <VehicleInformation />;
      case 3:
        return <SubmissionInformation />;
      default:
        return <div>Not Found</div>;
    }
  }

  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };

  const removeActive = () => {
    setIsActive(false);
  };

  /* Pulls the selection value provided by the state tag dropdown menu and assigns it to the 
  state variable in the guestPassData Object. This function is passed as a prop into the StatesDropDown.js file*/
  function pull_stateTag_data(state) {}

  /* Pulls the selection value provided by the pass type dropdown menu and assigns it to the 
  passType variable in the guestPassData Object. This function is passed as a prop into the PassType.js file*/
  function pull_passType_data(passType) {}

  /* Pulls the selection value provided by the unit number dropdown menu and assigns it to the 
  unitNumber variable in the guestPassData Object. This function is passed as a prop into the UnitNumbersDropDown.js file*/
  function pull_unitNumber_data(unitNumber) {}

  /**  Handles phone number entry within the phone number field. 
  Assigns the value to the phoneNumber variable in the guestPassData Object.  */
  function handlePhoneNumberInput(number) {}

  function pull_spot_number(spotNumber) {}

  function welcomePage() {
    return (
      <Box
        sx={{
          width: "100%",
          marginX: "auto",
          display: "flex",
          maxWidth: isMobile ? "650px" : "800px",
          alignItems: "center",
          fontFamily: "Poppins",
          flexDirection: "column",
          marginTop: isMobile ? 1 : "2rem",
        }}
      >
        <Typography
          pt={2}
          pb={3}
          fontWeight={"bold"}
          textAlign={"center"}
          fontSize={isMobile ? 25 : 30}
        >
          Welcome to <br /> 10101 Grosvenor Pl
        </Typography>
        <Typography
          pb={5}
          component={"span"}
          fontSize={isMobile ? 17 : 18}
          lineHeight={isMobile ? 1.5 : 2}
        >
          We are pleased to provide you with an easy and convenient way to
          register your guest's vehicle for a temporary or overnight guest pass
          through our online portal.
          <br />
          <br />
          In the following steps, you will be asked to provide some essential
          information, including:
          <ol>
            <li>
              <strong>Guest Name:</strong> The name of the individual who will
              be visiting.
            </li>
            <li>
              <strong>Vehicle Information:</strong> Details about the vehicle,
              including make, model, color, state, and tag number.
            </li>
            <li>
              <strong>Unit Number:</strong> The unit number of the resident
              being visited.
            </li>
          </ol>
          <br />
          We offer three types of parking passes to suit your guest's needs:
          <ol>
            <li>
              <strong>Overnight Pass:</strong> Valid for overnight parking.
            </li>
            <li>
              <strong>Weekday Pass:</strong> Allows parking between 8 AM and 6
              PM on weekdays.
            </li>
            <li>
              <strong>Contractor Pass:</strong> Designed for contractors and
              follows the same time restrictions as the weekday pass.
            </li>
          </ol>
          <br />
          Please proceed with the registration process by completing the
          upcoming forms. If you have any questions or need assistance, feel
          free to reach out to our management office. Thank you for helping us
          ensure a smooth and enjoyable visit to 10101 Grosvenor Condominiums!
        </Typography>
      </Box>
    );
  }

  return (
    <Stack
      gap={1}
      width={"100%"}
      marginX={"auto"}
      boxSizing={"border-box"}
      paddingX={isMobile ? 2 : 5}
      paddingY={isMobile ? 10 : 0}
      paddingTop={isMobile ? 1 : 5}
      maxWidth={isMobile ? "100%" : "1000px"}
    >
      {isMobile ? (
        <React.Fragment>
          <Box justifyContent={"flex-end"}>
            <MobileNavBar
              isActive={isActive}
              removeAction={removeActive}
              toggleActiveClass={toggleActiveClass}
            />
          </Box>

          {isActive ? (
            <></>
          ) : (
            <Box pt={3} width={"100%"} maxWidth={"600px"}>
              <MobileStepper
                variant={"text"}
                position={"bottom"}
                steps={totalSteps()}
                activeStep={activeStep}
                nextButton={
                  <Button
                    size={"large"}
                    onClick={handleNext}
                    disabled={isLastStep()}
                  >
                    <Typography> Next</Typography>
                  </Button>
                }
                backButton={
                  <Button
                    size="large"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    <Typography>Back</Typography>
                  </Button>
                }
              />

              <Box>{fetchStepForm(activeStep)}</Box>
            </Box>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <DesktopNavBar />
          <Box padding={isMobile ? 2 : 4} width="100%">
            <Stack spacing={5}>
              <Typography fontSize={30} textAlign={"center"}>
                Guest Pass Registration
              </Typography>

              <Box>
                <Typography fontSize={17} textAlign={"center"}>
                  Please fill out the following fields to request your guest
                  parking pass.
                </Typography>
              </Box>

              <Box>
                <UnitNumberDropDown onChange={pull_unitNumber_data} />
              </Box>

              <Box>
                <ParkingPassType onChange={pull_passType_data} />
              </Box>

              <Box>
                <ParkingSpotsDropDown onChange={pull_spot_number} />
              </Box>

              {[
                "Guest Name",
                "Guest Phone Number",
                "VehicleMake",
                "Vehicle Model",
                "Vehicle Color",
                "Vehicle Tag Number",
              ].map((label, index) => (
                <Box key={index}>
                  {index !== 1 ? (
                    <TextField
                      fullWidth
                      label={label}
                      name={textFieldNames.at(index)}
                      // onChange={(e) => getInput(e)}
                    />
                  ) : (
                    <PhoneInput
                      country={"us"}
                      disableDropdown
                      disableCountryCode
                      disableCountryGuess
                      // value={guestPassData.phoneNumber}
                      onChange={handlePhoneNumberInput}
                      inputStyle={{
                        width: "100%",
                        backgroundColor: "#e6e6cf",
                      }}
                      specialLabel=""
                      inputProps={{
                        required: true,
                      }}
                    />
                  )}
                </Box>
              ))}

              <Box>
                <StatesDropDown onChange={pull_stateTag_data} />
              </Box>

              <Box textAlign={"center"}>
                <Button fullWidth color={"ochre"} variant={"contained"}>
                  <Typography fontWeight={"bold"} style={{ color: "white" }}>
                    Submit
                  </Typography>
                </Button>
              </Box>
            </Stack>
          </Box>
        </React.Fragment>
      )}
    </Stack>
  );
}
