import {
  Box,
  Button,
  createTheme,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import MobileNavBar from "../Components/MobileNavBar";
import DesktopNavBar from "../Components/DesktopNavBar";

export default function About() {
  const location = useLocation();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  const theme = createTheme({
    typography: {
      fontFamily: ["Poppins", "cursive"].join(","),
      fontSize: 15,
    },
    palette: {
      ochre: {
        main: "#a881af",
        light: "#E9DB5D",
        dark: "#80669d",
        contrastText: "#242105",
      },
      black: {
        main: "#000000",
      },
    },
  });

  function closeMenu() {
    setMenuOpen(false);
  }

  function openMenu() {
    setMenuOpen(true);
  }

  function menuGrid() {
    return (
      <Box
        sx={{
          marginTop: 2,
          paddingX: "0.5rem",
        }}
      >
        <Button color={"black"} onClick={openMenu}>
          <GridMenuIcon fontSize="large"></GridMenuIcon>
        </Button>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      {isMenuOpen ? (
        <React.Fragment>
          <Box
            sx={{
              marginTop: 2,
              paddingX: "0.5rem",
            }}
          >
            <Button
              color={"black"}
              onClick={closeMenu}
              sx={{ justifyContent: "flex-start" }}
            >
              <CloseIcon fontSize="large" />
            </Button>
          </Box>
          <MobileNavBar />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {isMobile ? menuGrid() : <DesktopNavBar />}

          <Box
            sx={{
              width: "90%",
              marginX: "auto",
              display: "flex",
              paddingX: "1rem",
              maxWidth: "600px",
              alignItems: "center",
              fontFamily: "Poppins",
              paddingBottom: "5rem",
              flexDirection: "column",
              marginTop: isMobile ? 4 : "1rem",
            }}
          >
            <Typography variant={isMobile ? "h6" : "h4"}>
              Welcome to 10101 Grosvenor PL
            </Typography>
          </Box>
        </React.Fragment>
      )}
    </ThemeProvider>
  );
}
