import {
  Stack,
  Button,
  Typography,
  createTheme,
  ThemeProvider,
  Box,
} from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import GavelIcon from "@mui/icons-material/Gavel";
import HistoryIcon from "@mui/icons-material/History";
import { useLocation, useNavigate } from "react-router-dom";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import LoginTwoToneIcon from "@mui/icons-material/LoginTwoTone";

export default function MobileNavBar({
  isActive,
  toggleActiveClass,
  removeActive,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = createTheme({
    typography: {
      fontSize: 25,
      fontFamily: ["Poppins", "cursive"].join(","),
    },
    palette: {
      ochre: {
        main: "#a881af",
        light: "#E9DB5D",
        dark: "#80669d",
        contrastText: "#242105",
      },
      black: {
        main: "#000000",
      },
    },
  });

  function routeChange(route) {
    navigate(route);
  }

  const goToLogin = () => {
    toggleActiveClass();
    if (location === "/login") {
      window.location.reload();
    } else {
      routeChange("/login");
    }
  };

  const goToRegistration = () => {
    toggleActiveClass();
    if (location === "/registration" || location === "/") {
      window.location.reload();
    } else {
      routeChange("/");
    }
  };

  const goToRules = () => {
    toggleActiveClass();
    if (location === "/rules") {
      window.location.reload();
    } else {
      routeChange("/rules");
    }
  };

  const goToHistory = () => {
    toggleActiveClass();
    if (location === "/search") {
      window.location.reload();
    } else {
      routeChange("/search");
    }
  };

  const buttonData = [
    {
      label: "Register Guest",
      onClick: goToRegistration,
      icon: <LocalParkingIcon fontSize="small" />,
    },
    { label: "Parking History", icon: <HistoryIcon />, onClick: goToHistory },
    { label: "Parking Rules", icon: <GavelIcon />, onClick: goToRules },
    { label: "Signup", icon: <AccountBoxIcon /> },
    { label: "Login", icon: <LoginTwoToneIcon />, onClick: goToLogin },
  ];

  return (
    <ThemeProvider theme={theme}>
      {isActive ? (
        <React.Fragment>
          <Box display={"flex"} justifyContent="flex-end">
            <Button color={"black"} onClick={toggleActiveClass}>
              <CloseIcon />
            </Button>
          </Box>
          <Stack
            spacing={5}
            sx={{
              marginTop: 5,
              display: "flex",
              marginX: "0.5rem",
              paddingX: "1rem",
              borderRadius: "5px",
              fontFamily: "Poppins",
              paddingBottom: "5rem",
              flexDirection: "column",
            }}
          >
            {buttonData.map((button, index) => (
              <Button
                fullWidth
                key={index}
                color={"black"}
                onClick={button.onClick}
                sx={{ justifyContent: "flex-start" }}
              >
                <Stack direction="row" gap={2} alignItems="center">
                  {button.icon}
                  <Typography>{button.label}</Typography>
                </Stack>
              </Button>
            ))}
          </Stack>
        </React.Fragment>
      ) : (
        <Box display="flex" justifyContent="flex-end">
          <Button color={"black"} onClick={toggleActiveClass}>
            <MenuIcon />
          </Button>
        </Box>
      )}
    </ThemeProvider>
  );
}
