import Swal from "sweetalert2";

/**
 *
 * @param {String} field
 * @returns
 */
export const isEmpty = (field) => {
  let value = field.trim(" ").length;

  if (value === 0) {
    return true;
  }

  return false;
};

/**
 *
 * @param {String} fieldName
 * @returns
 */
export function emptyFieldMessage(fieldName) {
  return Swal.fire({
    icon: "error",
    title: fieldName + " Required",
    text:
      "Oops! It looks like the " +
      fieldName +
      " field is empty. Please fill it out with your " +
      fieldName +
      "  to proceed.",
  });
}

/**
 *
 * @param {*} fieldName
 * @returns Error dialouge box with the empty drop down menu that has been left empty
 */
export function dropDownNotSelected(fieldName) {
  return Swal.fire({
    icon: "error",
    title: fieldName + " Required",
    text:
      "Oops! It looks like the " +
      fieldName +
      " field has no option selected. Please select one of the options provided in the dropdown menu and try again.",
  });
}

export function displayError(errorTitle, errorMessage) {
  return Swal.fire({ icon: "error", title: errorTitle, text: errorMessage });
}

/**
 *
 * @param {*} guestData
 * @returns true or false if all of the fields within the guestData type are completely filled out.
 */
export function validateGuestInput(guestData) {
  if (isEmpty(guestData.unitNumber)) {
    dropDownNotSelected("Unit Number");
    return false;
  }
  if (isEmpty(guestData.passType)) {
    dropDownNotSelected("Parking Pass Type");
    return false;
  }
  if (isEmpty(guestData.guestName)) {
    emptyFieldMessage("Guest Full Name");
    return false;
  }
  if (isEmpty(guestData.phoneNumber)) {
    emptyFieldMessage("Guest Phone Number");
    return false;
  }
  if (isEmpty(guestData.vehicleMake)) {
    emptyFieldMessage("Vehicle Make");
    return false;
  }
  if (isEmpty(guestData.vehicleModel)) {
    emptyFieldMessage("Vehicle Model");
    return false;
  }
  if (isEmpty(guestData.vehicleColor)) {
    emptyFieldMessage("Vehicle Color");
    return false;
  }
  if (isEmpty(guestData.vehicleTagNumber)) {
    emptyFieldMessage("Vehicle Tag Number");
    return false;
  }
  if (isEmpty(guestData.vehicleState)) {
    dropDownNotSelected("Vehicle State");
    return false;
  }

  return true;
}
