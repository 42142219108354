import React from "react";
import parkingLotSpots from "./JSON/parkingSpots.json";
import { Select, MenuItem, Typography, FormControl } from "@mui/material";


export default function ParkingSpotsDropDown(props) {
  function handleChange(e) {
    props.onChange(e.target.value);
  }

  return (
    <FormControl fullWidth>
      <Select required displayEmpty onChange={handleChange}>
        <MenuItem>
          <Typography>Select Parking Spot Number</Typography>
        </MenuItem>
        {parkingLotSpots.map((spot) => (
          <MenuItem key={spot.spotName} value={spot.spotValue}>
            {spot.spotValue}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
