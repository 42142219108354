import {
  Stack,
  Button,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import GavelIcon from "@mui/icons-material/Gavel";
import HistoryIcon from "@mui/icons-material/History";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LocalParkingIcon from "@mui/icons-material/LocalParking";

export default function DesktopNavBar() {
  const navigate = useNavigate();
  const theme = createTheme({
    typography: {
      fontSize: 15,
      fontFamily: ["Poppins", "cursive"].join(","),
    },
    palette: {
      ochre: {
        main: "#a881af",
        light: "#E9DB5D",
        dark: "#80669d",
        contrastText: "#242105",
      },
      black: {
        main: "#000000",
      },
    },
  });

  function routeChange(route) {
    navigate(route);
  }

  const goTosignIn = () => {
    routeChange("/login");
  };

  const goToRegistration = () => {
    routeChange("/");
  };

  const goToRules = () => {
    routeChange("/rules");
  };

  const goToHistory = () => {
    routeChange("/search");
  };

  const buttonData = [
    {
      label: "Register Vehicle",
      onClick: goToRegistration,
      icon: <LocalParkingIcon fontSize="small" />,
    },
    { label: "Parking History", icon: <HistoryIcon />, onClick: goToHistory },
    { label: "Parking Rules", icon: <GavelIcon />, onClick: goToRules },
    { label: "log In", icon: <AccountBoxIcon />, onClick: goTosignIn },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Stack
        spacing={8}
        direction={"row"}
        justifyContent={"center"}
      >
        {buttonData.map((button, index) => (
          <Button key={index} color={"black"} onClick={button.onClick}>
            <Stack direction="row" gap={1} alignItems="center">
              {button.icon}
              <Typography>{button.label}</Typography>
            </Stack>
          </Button>
        ))}
      </Stack>
    </ThemeProvider>
  );
}
