import {
  Box,
  Button,
  createTheme,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { GridMenuIcon } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import MobileNavBar from "../Components/MobileNavBar";
import DesktopNavBar from "../Components/DesktopNavBar";
import { sendPasswordResetEmail } from "@firebase/auth";
import auth from "../firebase";
import { isEmpty } from "../Functions/RegistrationFunctions";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [hasNoEmail, setEmptyEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  useEffect(() => {
    setEmailSent(false);
  }, []);
  const theme = createTheme({
    typography: {
      fontFamily: ["Poppins", "cursive"].join(","),
      fontSize: 15,
    },
    palette: {
      ochre: {
        main: "#a881af",
        light: "#E9DB5D",
        dark: "#80669d",
        contrastText: "#242105",
      },
      black: {
        main: "#000000",
      },
    },
  });

  function menuGrid() {
    return (
      <Box
        sx={{
          marginTop: 2,
          paddingX: "0.5rem",
        }}
      >
        <Button color={"black"} onClick={openMenu}>
          <GridMenuIcon fontSize="large"></GridMenuIcon>
        </Button>
      </Box>
    );
  }

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  function verifyEmailInput() {
    if (isEmpty(email)) {
      setEmptyEmail(true);
    } else {
      setEmptyEmail(false);
    }

    return hasNoEmail;
  }

  function handleInput(e) {
    setEmail(e.target.value);
    sessionStorage.setItem("email", e.target.value);
  }

  function sendResetEmail() {
    if (verifyEmailInput) {
      sendPasswordResetEmail(auth, email).then(() => {
        setEmailSent(true);
      });
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {isMenuOpen ? (
        <React.Fragment>
          <Box
            sx={{
              marginTop: 2,
              paddingX: "0.5rem",
            }}
          >
            <Button
              color={"black"}
              onClick={closeMenu}
              sx={{ justifyContent: "flex-start" }}
            >
              <CloseIcon fontSize="large" />
            </Button>
          </Box>

          <MobileNavBar />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {isMobile ? menuGrid() : <DesktopNavBar />}
          <Box
            sx={{
              width: "90%",
              marginX: "auto",
              display: "flex",
              paddingX: "1rem",
              maxWidth: "600px",
              alignItems: "center",
              fontFamily: "Poppins",
              paddingBottom: "5rem",
              flexDirection: "column",
              marginTop: isMobile ? 4 : "2rem",
            }}
          >
            <Stack padding={isMobile ? 2 : 4} width="100%" gap={3}>
              {emailSent ? (
                <React.Fragment>
                  <Typography fontSize={25}>
                    Password Reset Email Sent
                  </Typography>
                  <Typography lineHeight={1.5}>
                    If an account with <strong>{email}</strong> matches, an
                    email will be sent with a link to them email address stored
                    in your profile so you can reset your password.
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Box>
                    <Typography fontSize={25}>Rest Password</Typography>
                  </Box>
                  {/* end of title box */}

                  <Box>
                    <Typography fontSize={18}>
                      Enter your email to receive a reset link
                    </Typography>
                  </Box>

                  <Stack>
                    <Typography>Email</Typography>
                    <TextField
                      fullwidth
                      name={"email"}
                      error={hasNoEmail}
                      onChange={(e) => handleInput(e)}
                    ></TextField>
                    {hasNoEmail ? (
                      <Typography align={"right"} fontSize={15}>
                        Email cannot be left empty
                      </Typography>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </Stack>

                  <Button
                    fullWidth
                    color={"ochre"}
                    variant={"contained"}
                    onClick={sendResetEmail}
                  >
                    <Typography fontWeight={"bold"} color={"white"}>
                      Send Email
                    </Typography>
                  </Button>
                </React.Fragment>
              )}
            </Stack>
          </Box>
        </React.Fragment>
      )}
    </ThemeProvider>
  );
}
