import {
  Box,
  Stack,
  Button,
  TextField,
  Typography,
  createTheme,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { GridMenuIcon } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import MobileNavBar from "../Components/MobileNavBar";
import ParkingPassType from "../Components/PassTypes";
import { useLocation, useNavigate } from "react-router-dom";
import StatesDropDown from "../Components/StatesDropDown";
import UnitNumberDropDown from "../Components/UnitsDropDown";
import ParkingSpotsDropDown from "../Components/ParkingSpotDropDown";
import {
  displayError,
  validateGuestInput,
} from "../Functions/RegistrationFunctions";
import DesktopNavBar from "../Components/DesktopNavBar";

export default function Registration() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [guestPassData, setGuestPassData] = useState({
    guestName: "",
    passType: "",
    unitNumber: "",
    phoneNumber: "",
    vehicleMake: "",
    vehicleModel: "",
    vehicleColor: "",
    vehicleState: "",
    vehicleTagNumber: "",
    spotNumber: "",
    confirmationNumber: "",
  });

  const textFieldNames = [
    "guestName",
    "phoneNumber",
    "vehicleMake",
    "vehicleModel",
    "vehicleColor",
    "vehicleTagNumber",
  ];

  const theme = createTheme({
    typography: {
      fontFamily: ["Poppins", "cursive"].join(","),
      fontSize: 15,
    },
    palette: {
      ochre: {
        main: "#a881af",
        light: "#E9DB5D",
        dark: "#80669d",
        contrastText: "#242105",
      },
      black: {
        main: "#000000",
      },
    },
  });

  function routeChange(route) {
    navigate(route);
  }

  function goToWaitingPage() {
    routeChange(
      "/guestStatus/" +
        guestPassData.confirmationNumber +
        "/" +
        guestPassData.unitNumber
    );
  }

  function getInput(e) {
    const { name, value } = e.target;
    setGuestPassData({
      ...guestPassData,
      [name]: value,
    });
  }

  async function checkPass() {
    if (validateGuestInput(guestPassData)) {
      const guestInformation =
        "<div style='font-family: poppins; color: black;'><p style='font-size: 24px;'>Please review the information below. <br/>Press continue if all information is accurate: <br/><br/> Guest Name: " +
        guestPassData.guestName +
        "<br/> Guest Phone Number: " +
        guestPassData.phoneNumber +
        "<br/> Unit Number: " +
        guestPassData.unitNumber +
        "<br/> Vehicle Make: " +
        guestPassData.vehicleMake +
        "<br/> Vehicle Model: " +
        guestPassData.vehicleModel +
        "<br/> Vehicle Color: " +
        guestPassData.vehicleColor +
        "<br/> Vehicle State: " +
        guestPassData.vehicleState +
        "<br/> Vehicle Tag Number: " +
        guestPassData.vehicleTagNumber +
        "</p> </div>";

      Swal.fire({
        title: "Confirm Guest Information",
        html: guestInformation,
        showDenyButton: true,
        confirmButtonText: "Submit Pass",
        denyButtonText: "Edit Information",
      }).then((confirmation) => {
        if (confirmation.isConfirmed) {
          sendRequest();
        }
      });
    }
  }

  async function sendRequest() {
    try {
      const result = await axios.post(
        "https://54.82.245.196:8080/api/guest/add",
        {
          guestName: guestPassData.guestName,
          passType: guestPassData.passType,
          unitNumber: guestPassData.unitNumber,
          guestPhoneNumber: guestPassData.phoneNumber,
          vehicle: {
            vehicleMake: guestPassData.vehicleMake,
            vehicleModel: guestPassData.vehicleModel,
            vehicleColor: guestPassData.vehicleColor,
            vehicleState: guestPassData.vehicleState,
            vehicleTagNumber: guestPassData.vehicleTagNumber,
          },
        }
      );
      if (result.status === 200) {
        Swal.fire({
          icon: "success",
          allowOutsideClick: false,
          confirmButtonText: "Continue",
          title: "Registration information",
          html: "<h3>Please wait as we send a notification to the resident and process your guest pass. You can check the status of your guest pass on the following page.</h3>",
        }).then((response) => {
          if (response.isConfirmed) {
            guestPassData.confirmationNumber = result.data;
            goToWaitingPage();
          }
        });
      }
    } catch (error) {
      displayError(
        "System Error",
        "Oops! We are having trouble submitting your parking pass request. Please refresh the page and try again."
      );
    }
  }

  /* Pulls the selection value provided by the state tag dropdown menu and assigns it to the 
  state variable in the guestPassData Object. This function is passed as a prop into the StatesDropDown.js file*/
  function pull_stateTag_data(state) {
    guestPassData.vehicleState = state;
  }

  /* Pulls the selection value provided by the pass type dropdown menu and assigns it to the 
  passType variable in the guestPassData Object. This function is passed as a prop into the PassType.js file*/
  function pull_passType_data(passType) {
    guestPassData.passType = passType;
  }

  /* Pulls the selection value provided by the unit number dropdown menu and assigns it to the 
  unitNumber variable in the guestPassData Object. This function is passed as a prop into the UnitNumbersDropDown.js file*/
  function pull_unitNumber_data(unitNumber) {
    guestPassData.unitNumber = unitNumber;
  }

  /**  Handles phone number entry within the phone number field. 
  Assigns the value to the phoneNumber variable in the guestPassData Object.  */
  function handlePhoneNumberInput(number) {
    guestPassData.phoneNumber = number;
  }

  function pull_spot_number(spotNumber) {
    guestPassData.spotNumber = spotNumber;
  }

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  function menuGrid() {
    return (
      <Box
        sx={{
          marginTop: 2,
          paddingX: "0.5rem",
        }}
      >
        <Button color={"black"} onClick={openMenu}>
          <GridMenuIcon fontSize="large"></GridMenuIcon>
        </Button>
      </Box>
    );
  }

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      {isMenuOpen ? (
        <React.Fragment>
          <Box
            sx={{
              marginTop: 2,
              paddingX: "0.5rem",
            }}
          >
            <Button
              color={"black"}
              onClick={closeMenu}
              sx={{ justifyContent: "flex-start" }}
            >
              <CloseIcon fontSize="large" />
            </Button>
          </Box>
          <MobileNavBar />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {isMobile ? menuGrid() : <DesktopNavBar />}

          <Box
            sx={{
              width: "90%",
              marginX: "auto",
              display: "flex",
              paddingX: "1rem",
              maxWidth: "600px",
              alignItems: "center",
              fontFamily: "Poppins",
              paddingBottom: "5rem",
              flexDirection: "column",
              marginTop: isMobile ? 4 : "1rem",
            }}
          >
            <Box padding={isMobile ? 2 : 4} width="100%">
              <Stack spacing={2.5}>
                <Box>
                  <Typography fontSize={30} textAlign={"center"}>
                    Guest Pass Registration
                  </Typography>
                </Box>

                <Box>
                  <Typography fontSize={17} textAlign={"center"}>
                    Please fill out the following fields to request your guest
                    parking pass.
                  </Typography>
                </Box>

                <Box>
                  <Typography>Unit Number</Typography>
                  <UnitNumberDropDown onChange={pull_unitNumber_data} />
                </Box>

                <Box>
                  <Typography>Parking Pass Type</Typography>
                  <ParkingPassType onChange={pull_passType_data} />
                </Box>

                <Box>
                  <Typography>Parking Space Number</Typography>
                  <ParkingSpotsDropDown onChange={pull_spot_number} />
                </Box>

                {[
                  "Guest Name",
                  "Guest Phone Number",
                  "Vehicle Make",
                  "Vehicle Model",
                  "Vehicle Color",
                  "Vehicle Tag Number",
                ].map((label, index) => (
                  <Box key={index}>
                    <Typography>{label}</Typography>
                    {index !== 1 ? (
                      <TextField
                        fullWidth
                        name={textFieldNames.at(index)}
                        onChange={(e) => getInput(e)}
                      />
                    ) : (
                      <PhoneInput
                        country={"us"}
                        disableDropdown
                        disableCountryCode
                        disableCountryGuess
                        value={guestPassData.phoneNumber}
                        onChange={handlePhoneNumberInput}
                        inputStyle={{
                          width: "100%",
                          backgroundColor: "ede8",
                        }}
                        specialLabel=""
                        inputProps={{
                          required: true,
                          autoFocus: true,
                        }}
                      />
                    )}
                  </Box>
                ))}

                <Box>
                  <Typography>Vehicle State</Typography>
                  <StatesDropDown onChange={pull_stateTag_data} />
                </Box>

                <Box textAlign={"center"}>
                  <Button
                    fullWidth
                    color={"ochre"}
                    onClick={checkPass}
                    variant={"contained"}
                  >
                    <Typography fontWeight={"bold"} style={{ color: "white" }}>
                      Submit
                    </Typography>
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Box>
        </React.Fragment>
      )}
    </ThemeProvider>
  );
}
