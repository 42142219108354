import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function ParkingPassType(props) {
  const [passType, setPassType] = useState("");

  const handleChange = (e) => {
    setPassType(e.target.value);
    props.onChange(e.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>Pass Type</InputLabel>
      <Select
        required
        value={passType}
        label={"Pass Type"}
        onChange={handleChange}
      >
        <MenuItem disabled>Select Parking Pass Type</MenuItem>
        <MenuItem key={"Overnight"} value="OVERNIGHT">
          24 Hour Pass (VALID FOR OVERNIGHT STAY)
        </MenuItem>
        <MenuItem key={"Daily"} value="WEEKDAY">
          Weekday Visitor Pass (8AM - 6PM)
        </MenuItem>
        <MenuItem key={"Contractor"} value="Contractor">
          Contractor (VALID FROM 8AM - 6PM)
        </MenuItem>
      </Select>
    </FormControl>
  );
}
