// Import the functions you need from the SDKs you need
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCXvks2t-cqsvhLbWxV-9Cv3DykuK6IjAs",
  authDomain: "parking-registration-app.firebaseapp.com",
  databaseURL: "https://parking-registration-app-default-rtdb.firebaseio.com",
  projectId: "parking-registration-app",
  storageBucket: "parking-registration-app.appspot.com",
  messagingSenderId: "1033601132989",
  appId: "1:1033601132989:web:c89857eda5776b77da8ad4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default auth;
